html, body {
  background-color: white !important;
}

.dss-header {  
  border-bottom: 5px solid var(--tmpp-main-green);  
}

.header-logo {
  max-Width: 70px;
  max-Height: 70px;
}

.dss-footer {
  background-color: var(--tmpp-main-green);
  position: fixed;
  bottom: 0;  
  width: 100%;
  color: white;
}

.dss-footer a {
  color: white;
}

.footer-badge {
  max-height: 100px;
}

.is-circle {
  display: inline-block;  
  border-radius: 50% !important;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;  
}

.main {  
  padding-bottom: 200px;
}

.button.is-multiline {
  white-space: normal;
  text-align: center;  
}

.i-button {
  width: 50px !important;
  height:  50px !important;
  line-height: 30px !important;  
}

.has-black-border {
  border: black solid 2px !important;
}